/* eslint-disable no-undef */
import { register } from 'register-service-worker'

register(`${process.env.BASE_URL}service-worker-day.js`, {
  async ready (registration) {
    try {
      const status = await navigator.permissions.query({
        name: 'periodic-background-sync',
      });

      if (status.state === 'granted' && 'periodicSync' in registration) {
        try {
          await registration.periodicSync.register("sync-weather", {
            minInterval: 10 * 60 * 1000,
          });
        } catch (err) {
          console.error(err.name, err.message);
        }
        
        console.log('Periodic background sync registered.');
      }
    } catch(e) {
      console.log("Periodic Sync could not be registered!", e);
    }
  },
  registered () {
    console.log('Service worker has been registered.')
  },
  cached () {
    console.log('Content has been cached for offline use.')
  }
})
