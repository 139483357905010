/* eslint-disable no-unused-vars */

import { createStore } from 'vuex'
import * as idb from 'idb-keyval'

const appName = "today-weather"
const appDB = idb.createStore(`${appName}-database`, `${appName}-store`)

const store = createStore ({
  state: {
    location: { code: "", sido: "", gugun: "", dong: "", coords: { latitude: 0.0, longitude: 0.0 } },
    weather: { 
      update:"",
      area:"",
      rainSnow:{
        rain:{h24:0,day:0},snow:{h24:0,day:0},estimate:{type:"",fall:0}
      },
      now:{
        temperature:0,feelTemp:0.0,humidity:0,rainfall: 0,raintype:"",windDeg:"",windSpeed:0.0,heatIndex:0,discomfort:0,uv:0,minTemp:0.0,maxTemp:0.0
      },
      air:{
        khai:{grade:1,value:0},
        o3:{grade:1,value:0.0},
        pm10:{grade:1,value:0},
        pm25:{grade:1,value:0},
        co:{grade:1,value:0.0},
        no2:{grade:1,value:0.0},
        so2:{grade:1,value:0.0}
      },
      short:{
        baseTime:"0000",sky:[],rainType:[],rainMeter:[],windDeg:[],windSpeed:[],humidity:[],temperature:[],thunderbolt:[]
      },
      predict:[],
      middle:[],
      yesterday:{temperature:0,humidity:0}}
  },
  getters: {
    location(state) {
      return state.location
    },
    weather(state) {
      return state.weather
    }
  },

  mutations: {
    location(state, payload){
      state.location = payload
    },

    weather(state, payload){
      state.weather = payload
    },
  },
  actions: {
    async loadLocation({commit}) {
      let val = await idb.get("location", appDB)
      
      if (val) {
        commit('location', val)
      }
    },

    async loadWeather({commit}) {
      let val = await idb.get("weather", appDB)
      
      if (val) {
        commit('weather', val)
      }
    },

    saveLocation({commit}, location) {
      idb.get("location", appDB).then((val) => {
        idb.set("location", location, appDB).then(() => {
          commit('location', location)
        })
      })
    },
    
    saveWeather({commit}, weather) {
      if (weather) {
        idb.get("weather", appDB).then((val) => {
          idb.set("weather", weather, appDB).then(() => {
            commit('weather', weather)
          })
        })
      }
    }
  },
});

export default store;