<template>
  <v-app id="inspire">
    <pull-refresh v-model="refresh" @refresh="refreshWeather">
      <v-card width="100%" min-height="100vh" class="mx-auto">
        <v-app-bar class="elevation-0 mx-auto backdrop-blur-sm !bg-white/60 border-b !border-gray-300" style="width: 100%; ">
          <template v-slot:prepend>
            <v-app-bar-nav-icon size="28"></v-app-bar-nav-icon>
          </template>
          <v-app-bar-title class="">
            <span class="font-body">{{ area }}</span>
            <v-icon icon="mdi-map-marker" size="18" class="mb-1 ml-1"></v-icon>
          </v-app-bar-title>
        </v-app-bar>
        <v-layout>
          <v-navigation-drawer
            v-model="drawer"
            temporary
            hidden
          >
            <v-list-item
              prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg"
              title="John Leider"
            ></v-list-item>

            <v-divider></v-divider>

            <v-list density="compact" nav>
              <v-list-item prepend-icon="mdi-view-dashboard" title="Home" value="home"></v-list-item>
              <v-list-item prepend-icon="mdi-forum" title="About" value="about"></v-list-item>
            </v-list>
          </v-navigation-drawer>

          <v-main>
            <v-container fluid class="bg-gray-100 pa-0 pt-1 scroll-smooth" style="margin-top: 64px; padding-bottom: 1px !important;">
              <div class="grid grid-cols-1 sm:grid-cols-2">
                <div>
                  <v-card
                    class="ma-1 mt-0 mb-3 flex"
                    elevation="0"
                  >
                    <v-card-text class="pa-0 relative ">
                      <div class="relative pa-4">
                        <div class="flex">
                          <div class="shrink font-number font-bold text-6xl mt-3" style="margin-left: -4px;">{{ weather.now.temperature.toFixed(1) }}</div>
                          <div class="shrink font-body font-bold text-4xl mt-4">°</div>
                        </div>
                        <div class="flex mt-4 mb-0 h-8">
                          <div class="flex-none font-body font-bold text-2xl">{{ forecast.sky }}</div>
                        </div>
                        <div class="flex flex-col mt-2" style="height: 24px !important;">
                          <div v-if="estimate.rain && !estimate.snow" class="flex font-body text-base">
                            <div class="flex-none">약</div>
                            <div class="flex-none ml-2 font-number font-bold">{{ estimate.rain.toFixed(0) }}</div>
                            <div class="flex-none font-noto -mt-0.5 ml-0.5">㎜</div>
                            <div class="flex-none">의 비가 예상됩니다.</div>
                          </div>
                          <div v-else-if ="!estimate.rain && estimate.snow" class="flex font-body text-base">
                            <div class="flex-none">약</div>
                            <div class="flex-none ml-2 font-number font-bold">{{ estimate.snow.toFixed(0) }}</div>
                            <div class="flex-none font-noto -mt-0.5 ml-0.5">㎝</div>
                            <div class="flex-none">의 눈이 예상됩니다.</div>
                          </div>
                          <div v-else-if ="estimate.rain && estimate.snow" class="flex font-body text-base">
                            <div class="flex-none">약</div>
                            <div class="flex-none ml-2 font-number font-bold">{{ estimate.rain.toFixed(0) }}</div>
                            <div class="flex-none font-noto -mt-0.5 ml-0.5">㎜</div>
                            <div class="flex-none">의 비와</div>
                            <div class="flex-none ml-2 font-number font-bold">{{ estimate.snow.toFixed(0) }}</div>
                            <div class="flex-none font-noto -mt-0.5 ml-0.5">㎝</div>
                            <div class="flex-none">의 눈이 예상됩니다.</div>
                          </div>
                        </div>
                        <div class="flex flex-col mt-2 h-20">
                          <div class="grow"></div>
                          <div v-show="weather.rainSnow.rain.day || weather.rainSnow.rain.h24 || weather.rainSnow.snow.day || weather.rainSnow.snow.h24" class="flex font-body text-base">
                            <div v-show="weather.rainSnow.rain.day || weather.rainSnow.rain.h24" class="flex font-body">
                              <div class="flex flex-none">
                                <div class="flex-none">강수</div>
                                <div class="flex-none ml-2 font-number font-bold">{{ weather.rainSnow.rain.day.toFixed(1) }}</div>
                                <div class="flex-none font-noto -mt-0.5 ml-0.5">㎜</div>
                                <div v-if="weather.rainSnow.rain.day != weather.rainSnow.rain.h24" class="flex flex-none ml-2">
                                  <div class="shrink">(</div>
                                  <div class="shrink ml-0.5 font-number font-bold">{{ weather.rainSnow.rain.h24.toFixed(1) }}</div>
                                  <div class="shrink font-noto -mt-0.5 ml-0.5">㎜</div>
                                  <div class="shrink ml-0.5">)</div>
                                </div>
                              </div>
                            </div>
                            <div v-show="(weather.rainSnow.rain.day || weather.rainSnow.rain.h24) && (weather.rainSnow.snow.day || weather.rainSnow.snow.h24)" class="flex-none ml-3 mr-2">/</div>
                            <div v-show="weather.rainSnow.snow.day || weather.rainSnow.snow.h24" class="flex font-body">
                              <div class="flex flex-none">
                                <div class="flex-none">적설</div>
                                <div class="flex-none ml-2 font-number font-bold">{{ weather.rainSnow.snow.day.toFixed(1) }}</div>
                                <div class="flex-none font-noto -mt-0.5 ml-0.5 text-gray-500">㎝</div>
                                <div v-if="weather.rainSnow.snow.day != weather.rainSnow.snow.h24" class="flex flex-none ml-2">
                                  <div class="shrink">(</div>
                                  <div class="shrink font-number font-bold ml-0.5">{{ weather.rainSnow.snow.h24.toFixed(1) }}</div>
                                  <div class="shrink font-noto -mt-0.5 ml-0.5 text-gray-500">㎝</div>
                                  <div class="shrink ml-0.5">)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex font-body text-base mt-1">
                            <div class="flex">
                              <div class="shrink">최저</div>
                              <div class="shrink ml-2 font-number font-bold">{{ weather.now.minTemp.toFixed(0) }}</div>
                              <div class="shrink font-noto -mt-0.5">°</div>
                            </div>
                            <div class="flex-none ml-2 mr-2">/</div>
                            <div class="flex">
                              <div class="shrink">최고</div>
                              <div class="shrink ml-2 font-number font-bold">{{ weather.now.maxTemp.toFixed(0) }}</div>
                              <div class="shrink font-noto -mt-0.5">°</div>
                            </div>
                            <div class="flex-none ml-2 mr-2">/</div>
                            <div class="flex mr-4">
                              <div class="shrink">체감</div>
                              <div class="shrink ml-2 font-number font-bold">{{ weather.now.feelTemp.toFixed(1) }}</div>
                              <div class="shrink font-noto -mt-0.5">°</div>
                            </div>
                          </div>
                          <div class="flex font-body mt-1">
                            <div class="flex-none text-base">{{ forecast.today }}</div>
                          </div>
                        </div>
                        <div class="absolute" style="top:10px;right:20px"><v-icon :icon="forecast.icon" size="120" :color="getPredictSkyColor(forecast.now.sky, forecast.now.fall)" class="float-right"></v-icon></div>
                      </div>
                    </v-card-text> 
                    <v-progress-circular v-show="loading" indeterminate color="grey" size="12" width="3" style="position: absolute; top: 3px; right: 3px;"></v-progress-circular>
                  </v-card>

                </div>

                <div>
                  <v-card elevation="0" class="ma-1 mt-0 mb-3 flex">
                    <v-card-text class="pa-0">
                      <div class="grid grid-cols-4 divide-x !divide-gray-300">
                        <div>
                          <div class="flex border-b !border-gray-300">
                            <div class="grow basis-0"></div>
                            <div class="flex text-right justify-center items-center h-16">
                              <span class="font-number ml-2 font-bold text-2xl">{{ weather.now.humidity }}</span>
                              <span class="flex-none font-noto ml-1 mt-1.5 text-md text-gray-500">%</span>
                            </div>
                            <div class="grow"></div>
                          </div>
                          <div class="flex pa-2 pl-0 pr-0">
                            <div class="grow"></div>
                            <div class="flex-none w-4">
                              <v-icon icon="mdi-water-outline" class="-mt-1 ml-0.5" size="20"></v-icon>
                            </div>
                            <div class="w-12 font-body text-center text-sm">습도</div>
                            <div class="grow"></div>
                          </div>
                        </div>
                        <div>
                          <div class="flex border-b !border-gray-300">
                            <div class="grow"></div>
                            <div class="flex text-right justify-center items-center h-16">
                              <span class="font-number ml-2 font-bold text-2xl">{{ weather.now.windSpeed.toFixed(1) }}</span>
                              <span class="flex-none font-noto ml-1 mt-1.5 text-md text-gray-500">㎧</span>
                            </div>
                            <div class="grow"></div>
                          </div>
                          <div class="flex pa-2 pl-0 pr-0">
                            <div class="grow"></div>
                            <div class="flex-none w-4">
                              <v-icon icon="mdi-wind-power-outline" class="-mt-1 ml-0.5" size="16"></v-icon>
                            </div>
                            <div class="w-12 font-body text-center text-sm">바람</div>
                            <div class="grow"></div>
                          </div>
                        </div>
                        <div>
                          <div class="flex border-b !border-gray-300">
                            <div class="grow"></div>
                            <div class="flex text-right justify-center items-center h-16">
                              <span v-if="weather.now.uv" class="font-number font-bold text-2xl ml-2 mr-2 ">{{ weather.now.uv }}</span>
                              <span v-else class="font-number font-bold text-3xl ml-2 mr-2 ">-</span>
                            </div>
                            <div class="grow"></div>
                          </div>
                          <div class="flex pa-2 pl-0 pr-0">
                            <div class="grow"></div>
                            <div class="flex-none w-4">
                              <v-icon icon="mdi-sun-wireless" class="-mt-0.5 ml-0.5" size="18"></v-icon>
                            </div>
                            <div class="w-16 font-body text-center text-sm">자외선</div>
                            <div class="grow"></div>
                          </div>
                        </div>
                        <div class="">
                          <div class="flex border-b !border-gray-300">
                            <div class="grow"></div>
                            <div class="flex text-right justify-center items-center h-16">
                              <span class="font-number font-bold text-2xl ml-2 mr-2 ">{{ weather.now.discomfort }}</span>
                            </div>
                            <div class="grow"></div>
                          </div>
                          <div class="flex pa-2 pl-0 pr-0">
                            <div class="grow"></div>
                            <div class="flex-none w-4">
                              <v-icon icon="mdi-emoticon-outline" class="-mt-0.5 ml-0.5" size="18"></v-icon>
                            </div>
                            <div class="w-16 font-body text-center text-sm">불쾌지수</div>
                            <div class="grow"></div>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                  <v-card elevation="0" class="ma-1 mt-0 mb-3 flex">
                    <v-card-text class="pa-0">
                      <div class="flex">
                        <div class="flex-none pa-3 pl-6">
                          <div class="">
                            <v-icon icon="mdi-weather-dust" :color="getAirGradeColor(weather.air.khai.grade)" class="drop-shadow-lg opacity-60" size="100"></v-icon>
                          </div>
                        </div>
                        <div class="grow"></div>
                        <div class="grow pa-3 pl-0 bg-white rounded" style="max-width: 250px; min-width: 175px;">
                          <v-row no-gutters class="mt-0">
                            <v-col class="flex">
                              <div class="flex-none font-body text-sm h-5 leading-8">미세먼지</div>
                              <div class="grow font-body font-bold ml-2 text-base h-5 leading-8 text-right">{{ weather.air.pm10.value ?? '-' }}</div>
                              <div class="flex-none font-body ml-1 text-xs text-gray-500 w-10 h-5 leading-8">㎍/㎥</div>
                              <div class="flex-none text-md w-5 h-5" style="margin-top: 3px"><v-icon icon="mdi-circle" size="15" :color="getAirGradeColor(weather.air.pm10.grade)"></v-icon></div>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="mt-0">
                            <v-col class="flex">
                              <div class="flex-none font-body text-sm h-5 leading-8">초미세먼지</div>
                              <div class="grow font-body font-bold ml-2 text-sm h-5 leading-8 text-right">{{ weather.air.pm25.value ?? '-' }}</div>
                              <div class="flex-none font-body ml-1 text-xs text-gray-500 w-10 h-5 leading-8">㎍/㎥</div>
                              <div class="flex-none text-md w-5 h-5" style="margin-top: 3px"><v-icon icon="mdi-circle" size="15" :color="getAirGradeColor(weather.air.pm25.grade)"></v-icon></div>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="mt-0">
                            <v-col class="flex">
                              <div class="flex-none font-body text-sm h-5 leading-8">오존</div>
                              <div class="grow font-body font-bold ml-2 text-base h-5 leading-8 text-right">{{ weather.air.o3.value ?? '-' }}</div>
                              <div class="flex-none font-body ml-1 text-xs text-gray-500 w-10 h-5 leading-8">ppm</div>
                              <div class="flex-none text-md w-5 h-5" style="margin-top: 3px"><v-icon icon="mdi-circle" size="15" :color="getAirGradeColor(weather.air.o3.grade)"></v-icon></div>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="mt-0">
                            <v-col class="flex">
                              <div class="flex-none font-body text-sm h-5 leading-8">일산화탄소</div>
                              <div class="grow font-body font-bold ml-2 text-base h-5 leading-8 text-right">{{ weather.air.co.value ?? '-' }}</div>
                              <div class="flex-none font-body ml-1 text-xs text-gray-500 w-10 h-5 leading-8">ppm</div>
                              <div class="flex-none text-md w-5 h-5" style="margin-top: 3px"><v-icon icon="mdi-circle" size="15" :color="getAirGradeColor(weather.air.co.grade)"></v-icon></div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div class="flex border-t !border-gray-300 pa-1" style="margin-top: 6px;">
                        <div class="grow"></div>
                        <div class="flex">
                          <div class="flex-none font-body text-sm ml-3">좋음</div>
                          <div class="flex-none text-md ml-1 -mt-0.5"><v-icon icon="mdi-circle" size="12" color="#60a5fa"></v-icon></div>
                        </div>
                        <div class="flex">
                          <div class="flex-none font-body text-sm ml-3">보통</div>
                          <div class="flex-none text-md ml-1 -mt-0.5"><v-icon icon="mdi-circle" size="12" color="#4ade80"></v-icon></div>
                        </div>
                        <div class="flex">
                          <div class="flex-none font-body text-sm ml-3">나쁨</div>
                          <div class="flex-none text-md ml-1 -mt-0.5"><v-icon icon="mdi-circle" size="12" color="#facc15"></v-icon></div>
                        </div>
                        <div class="flex">
                          <div class="flex-none font-body text-sm ml-3">매우나쁨</div>
                          <div class="flex-none text-md ml-1 -mt-0.5"><v-icon icon="mdi-circle" size="12" color="#f87171"></v-icon></div>
                        </div>
                        <div class="flex mr-2">
                          <div class="flex-none font-body text-sm ml-3">없음</div>
                          <div class="flex-none text-md ml-1 -mt-0.5"><v-icon icon="mdi-circle" size="12" color="#e5e7eb"></v-icon></div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                </div>

                <div>
                  <v-card v-if="forecast.short.length >= 6" elevation="0" class="ma-1 mt-0 mb-3">
                    <v-card-text class="pa-0">
                      <div class="flex scroll-smooth overflow-x-auto divide-x !divide-gray-300">
                        <div class="flex-none" style="width: calc(calc(100vw - 8px) / 6); max-width: calc(calc(448px - 8px) / 6)" v-for="item in forecast.short" :key="item">
                          <div class="text-center text-xs font-number pt-1.5 pb-1.5 border-b !border-gray-300">{{ item.time }}시</div>
                          <div class="text-center pt-2 pb-1"><v-icon :icon="getPredictSkyIcon(item.sky, item.rainType, item.time)" size="28"></v-icon></div>
                          <div class="flex pb-1 border-b border-dotted !border-gray-300">
                            <div class="grow"></div>
                            <div v-if="item.rainMeter > 0" class="flex">
                              <div class="grow font-number ml-2 font-bold text-right text-xs">{{ item.rainMeter }}</div>
                              <div class="flex-none font-number ml-0.5 text-xs text-gray-500">{{ getRainSnowUnit(item.rainType) }}</div>
                            </div>
                            <div v-else class="flex">
                              <div class="flex-none font-noto text-xs text-center w-6">-</div>
                            </div>
                            <div class="grow"></div>
                          </div>
                          <div class="flex pa-1">
                            <div class="grow"></div>
                            <div class="flex-none font-number ml-2 text-right font-bold text-sm">{{ item.temperature }}</div>
                            <div class="flex-none font-noto ml-0.5 text-xs text-gray-500">°</div>
                            <div class="grow"></div>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="sm:row-span-2">
                  <v-card class="ma-1 mt-0 mb-3" elevation="0" >
                    <v-card-text class="pa-0 scroll-smooth overflow-y-auto">
                      <div v-for="(item, index) in weather.predict" :key="item">
                        <div v-if="index > 0" class="border-b !border-gray-300"></div>
                        <div class="flex-none flex">
                          <div class="font-body text-center text-base w-32 pa-2 pt-3">{{ getWeekday(item.date) }}</div>
                          <div class="w-4"></div>
                          <div class="text-center w-12 pa-2 pl-0 pr-0"><v-icon :icon="getPredictSkyIcon(item.sky, item.type)" class="mt-0.5" size="32"></v-icon></div>
                          <div class="grow"></div>
                          <div class="w-16 pa-3 pl-0 pr-0">
                            <div class="flex mt-0.5">
                              <div class="grow font-number text-center text-sm w-10">{{ item.percent }} %</div>
                            </div>
                          </div>
                          <div class="w-12 pa-3 pl-0 pr-0">
                            <div class="flex mt-0.5">
                              <div class="grow font-number text-center text-sm w-10">{{ getRainSnowAmount(item) }} {{ getRainSnowUnit(item.type) }}</div>
                            </div>
                          </div>
                          <div class="grow"></div>
                          <div class="w-4"></div>
                          <div class="pa-3 pl-0 pr-0">
                            <div v-if="item.tempMin < 999" class="flex w-10 text-center">
                              <div class="grow"></div>
                              <div class="font-number text-base" style="padding-top: 1px;">{{ item.tempMin.toFixed(0) }}</div>
                              <div class="font-noto text-sm text-gray-500" style="padding-top: 1px;">°</div>
                              <div class="grow"></div>
                            </div>
                            <div v-else class="flex">
                              <div class="font-number text-center text-base w-10" style="padding-top: 1px;">-</div>
                            </div>
                          </div>
                          <div class="pa-3 pl-0 pr-0">
                            <div class="font-number text-center text-base w-3" style="padding-top: 1px;">/</div>
                          </div>
                          <div class="pa-3 pl-0 pr-0">
                            <div v-if="item.tempMax > -999" class="flex w-10 text-center">
                              <div class="grow"></div>
                              <div class="font-number text-base" style="padding-top: 1px;">{{ item.tempMax.toFixed(0) }}</div>
                              <div class="font-noto text-sm text-gray-500" style="padding-top: 1px;">°</div>
                              <div class="grow"></div>
                            </div>
                            <div v-else class="flex">
                              <div class="font-number text-center text-base w-10" style="padding-top: 1px;">-</div>
                            </div>
                          </div>
                          <div class="w-2"></div>
                        </div>
                      </div>
                      <div v-for="(item) in weather.middle" :key="item">
                        <div class="border-b !border-gray-300"></div>
                        <div class="flex-none flex">
                          <div class="font-body text-center text-base w-32 pa-2 pt-3">{{ getWeekday(item.date) }}</div>
                          <div class="w-4"></div>
                          <div class="text-center w-12 pa-2 pl-0 pr-0"><v-icon :icon="getPredictSkyIcon(item.sky, item.type)" class="mt-0.5" size="32"></v-icon></div>
                          <div class="grow"></div>
                          <div class="w-16 pa-3 pl-0 pr-0">
                            <div class="flex mt-0.5">
                              <div class="grow font-number text-center text-sm w-10">{{ item.percent }} %</div>
                            </div>
                          </div>
                          <div class="w-12 pa-3 pl-0 pr-0">
                            <div class="flex mt-0.5">
                              <div class="grow font-number text-center text-sm w-10"></div>
                            </div>
                          </div>
                          <div class="grow"></div>
                          <div class="w-4"></div>
                          <div class="pa-3 pl-0 pr-0">
                            <div v-if="item.tempMin < 999" class="flex w-10 text-center">
                              <div class="grow"></div>
                              <div class="font-number text-base" style="padding-top: 1px;">{{ item.tempMin.toFixed(0) }}</div>
                              <div class="font-noto text-sm text-gray-500" style="padding-top: 1px;">°</div>
                              <div class="grow"></div>
                            </div>
                            <div v-else class="flex">
                              <div class="font-number text-center text-base w-10" style="padding-top: 1px;">-</div>
                            </div>
                          </div>
                          <div class="pa-3 pl-0 pr-0">
                            <div class="font-number text-center text-base w-3" style="padding-top: 1px;">/</div>
                          </div>
                          <div class="pa-3 pl-0 pr-0">
                            <div v-if="item.tempMax > -999" class="flex w-10 text-center">
                              <div class="grow"></div>
                              <div class="font-number text-base" style="padding-top: 1px;">{{ item.tempMax.toFixed(0) }}</div>
                              <div class="font-noto text-sm text-gray-500" style="padding-top: 1px;">°</div>
                              <div class="grow"></div>
                            </div>
                            <div v-else class="flex">
                              <div class="font-number text-center text-base w-10" style="padding-top: 1px;">-</div>
                            </div>
                          </div>
                          <div class="w-2"></div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div>
                  <v-card class="ma-1 mt-0 mb-3 pa-1" elevation="0" >
                    <v-img max-height="370" cover :src="satellite.path" class="rounded"></v-img>
                  </v-card>
                </div>
              </div>
            </v-container>
          </v-main>
        </v-layout>

        <v-footer class="pa-0">
          <v-container fluid class="bg-gray-100 pa-2 pt-4 pb-4 ma-0">
            <v-row no-gutters>
              <v-col class="font-body text-sm text-gray-800">※ 날씨 업데이트</v-col>
            </v-row>
            <v-row no-gutters class="mt-0">
              <v-col class="pl-4 ml-0.5 font-body text-xs text-gray-500">{{ weather.update }}</v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col class="font-body text-sm text-gray-800">※ 출처</v-col>
            </v-row>
            <v-row no-gutters class="mt-0">
              <v-col class="pl-4 ml-0.5 font-body text-xs text-gray-500">기상청 / 환경부 / 한국환경공단</v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col class="font-body text-sm text-gray-800">※ 데이터 오류 가능성</v-col>
            </v-row>
            <v-row no-gutters class="mt-0">
              <v-col class="pl-4 ml-0.5 font-body text-xs text-gray-500">데이터는 실시간 관측된 자료이며 측정소 현지 사정이나 <br>데이터의 수신상태에 따라 미수신 될 수 있음</v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-card>
    </pull-refresh>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { useHead } from '@unhead/vue'
  import PullRefresh from "./components/PullRefresh.vue"
  
  // eslint-disable-next-line
  const print = function(x) { console.log(x) }

  export default {
    components: {
      PullRefresh,
    },
    data: () => ({
      api: "https://api.monorain.net",
      polling: null,
      refresh: false,
      loading: false,
      drawer: false,
      predict: {
        date: ""
      },
      satellite: {
        datetime: "",
        path: ""
      },
      estimate : {
        rain: 0,
        snow: 0
      },
      forecast: {
        area: "",
        sky: "",
        today: "",
        uvIcon: "",
        air: {
          khai: "",
          o3: "",
          co: "",
          no2: "",
          so2: "",
          pm10: "",
          pm25: ""
        },
        short: [],
        now : {
          sky: 1,
          fall: 0
        }
      }
    }),

    created() {
      if (window.location.hostname == "localhost") {
        //this.api = "http://localhost:443"
      }

      this.loadLocation().then(() => {
        this.reloadWeather()
      })

      this.polling = setInterval(() => {
        this.reloadWeather()
      }, 60000)

      navigator.serviceWorker.addEventListener('message', async (event) => {
        if (event.data.tag === 'sync-weather') {
          this.reloadWeather()
        }
      })

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "visible") {
          this.refreshWeather()
        }
      }, false)
    },
    mounted() {
      let initialScale = 1
      let deviceWidth = screen.width;
      let minWidth = 412;

      if (deviceWidth < minWidth) {
        initialScale = deviceWidth / minWidth
      }

      useHead({
        title: '오늘은 ?',
        meta: [
          { name: 'viewport', content: `width=${minWidth}, initial-scale=${initialScale}, maximum-scale=1.0` },
          { name: 'description', content: '오늘 날씨를 알려준다'},
          { name: 'keywords', content: 'weather, 날씨, 오늘'},
          { name: 'apple-mobile-web-app-title', content: '오늘은'},
          { name: 'apple-mobile-web-app-capable', content: 'yes'},
          { name: 'apple-mobile-web-app-status-bar-style', content: '#79b9ff'},
          { name: 'mobile-web-app-capable', content: 'yes'}
        ],
      })
    },
    computed: {
      ...mapGetters([
        'location',
        'weather',
      ]),

      area() {
        if (this.location?.dong) {
          return this.location.dong
        } else if (this.location?.gugun) {
          return this.location.gugun
        } else if (this.location?.sido) {
          return this.location.sido
        } else {
          return ""
        }
      },
    },
    beforeDestroy () {
      clearInterval(this.polling)
    },
    methods: {
      ...mapActions([
        'loadLocation',
        'loadWeather',
        'saveLocation',
        'saveWeather'
      ]),

      reloadWeather() {
        this.loadWeather().then(() => {
          this.checkUpdatedTime()
          this.parseWeather()
        })
      },

      refreshWeather() {
        this.refresh = false

        if (this.location.coords && this.location.code) {
          navigator.geolocation.getCurrentPosition((val) => {
            const distance = this.getDistance(val.coords.latitude, val.coords.longitude, this.location.coords.latitude, this.location.coords.longitude)

            if (distance > 1.0) {
              this.getCurrentGeo()
            } else {
              this.getWeather()
            }
          }, this.getGeoError)
        } else {
          this.getCurrentGeo()
        }
      },

      getCurrentGeo () {
        if (! navigator.geolocation) {
          this.setAlert('위치 정보를 찾을 수 없습니다.')
        } else {
          navigator.geolocation.getCurrentPosition(this.getLocation, this.getGeoError)
        }
      },

      getGeoError () {
        this.setAlert('위치 정보를 찾을 수 없습니다.')
      },

      getLocation(val) {
        const params = []

        const latitude  = val.coords.latitude
        const longitude = val.coords.longitude

        params.push(`latitude=${latitude}`)
        params.push(`longitude=${longitude}`)

        this.axios({
          url: `${this.api}/location?${params.join("&")}`,
          method: "GET"
        })
        .then((res) => {
            this.saveLocation({
              "coords": {
                "latitude"  : latitude,
                "longitude" : longitude
              },
              ...res.data
            })
            this.getWeather()
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        });
      },

      getDistance(lat1, lon1, lat2, lon2) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
          return 0;
        } else {
          let r1    = Math.PI * lat1/180
          let r2    = Math.PI * lat2/180
          let t     = lon1-lon2
          let rt    = Math.PI * t/180
          let dist  = Math.sin(r1) * Math.sin(r2) + Math.cos(r1) * Math.cos(r2) * Math.cos(rt)

          if (dist > 1) {
            dist = 1
          }

          dist = Math.acos(dist)
          dist = dist * 180/Math.PI
          dist = dist * 60 * 1.1515 * 1.609344

          return dist
        }
      },

      getWeather() {
        this.loading = true

        this.axios({
          url: `${this.api}/weather/${this.location.code}`,
          method: "GET"
        })
        .then((res) => {
          this.parseWeather()
          this.saveWeather(res.data);
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        });
      },

      getWeekday(text) {
        if (text) {
          const today   = new Date()
          const date    = new Date(text.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'))
          const weeknum = date.getDay();
          const week    = ["일", "월", "화", "수", "목", "금", "토"]

          if ((today.getYear() == date.getYear()) && (today.getMonth() == date.getMonth()) && (today.getDate() == date.getDate())) {
            return "오늘"
          }

          return String(date.getMonth() + 1).padStart(2, '0') + '/' +  String(date.getDate()).padStart(2, '0') + ' ('+ week[weeknum] + ')'
        }
      },

      getRainSnowAmount(predict) {
        if (predict.type == 1 || predict.type == 2 || predict.type == 4 || predict.type == 5 || predict.type == 6) {
          return predict.rain.toFixed(0)
        } else if (predict.type == 3 || predict.type == 7) {
          return predict.snow.toFixed(0)
        } else {
          return ""
        }
      },

      getRainSnowUnit(type) {
        const rain = '㎜'
        const snow = '㎝'

        if (type == 1 || type == 2 || type == 4 || type == 5 || type == 6) {
          return rain
        } else if (type == 3 || type == 7) {
          return snow
        } else {
          return ""
        }
      }, 

      checkUpdatedTime() {
        const now = new Date()
        const updated = new Date(this.weather.update)

        let diffTime = (now.getTime() - updated.getTime()) / 1000

        if (diffTime > 300) {
          this.refreshWeather()
        }
      },

      parseWeather() {
        this.forecast.short = []

        this.parseSky()
        this.parseAir()
        this.parseToday()
        this.parseShort()
        this.parseSatllite()
        
        this.loading = false
      },

      parseSky() {
        let hour    = (new Date()).getHours()
        let cloud   = this.weather.short.sky.at(0)
        let rain    = this.weather.now.raintype

        if (cloud == 1) {
          this.forecast.sky = "맑음"
          this.forecast.icon = "mdi-weather-sunny"

          if ((hour < 5) || (hour > 22)) {
            this.forecast.icon = "mdi-weather-night"
          }

          if (this.weather.now.windSpeed > 5) {
            this.forecast.icon = "mdi-weather-windy"
          }
        } else if (cloud == 2) {
          this.forecast.sky = "대체로 맑음"
          this.forecast.icon = "mdi-weather-partly-cloudy"

          if ((hour < 5) || (hour > 22)) {
            this.forecast.icon = "mdi-weather-night-partly-cloudy"
          }
        } else if (cloud == 3) {
          this.forecast.sky = "대체로 흐림"
          this.forecast.icon = "mdi-weather-partly-cloudy"

          if ((hour < 5) || (hour > 22)) {
            this.forecast.icon = "mdi-weather-night-partly-cloudy"
          }
        } else if (cloud == 4) {
          this.forecast.sky = "흐림"
          this.forecast.icon = "mdi-weather-cloudy"
        }
        
        if (rain == 1) {
          this.forecast.sky = "비"
          this.forecast.icon = "mdi-weather-pouring"
        } else if (rain == 2) {
          this.forecast.sky = "비 또는 눈"
          this.forecast.icon = "mdi-weather-snowy-rainy"
        } else if (rain == 3) {
          this.forecast.sky = "눈"
          this.forecast.icon = "mdi-weather-snowy-heavy"
        } else if (rain == 4) {
          this.forecast.sky = "소나기"
          this.forecast.icon = "mdi-weather-pouring"
        } else if (rain == 5) {
          this.forecast.sky = "약한 비"
          this.forecast.icon = "mdi-weather-rainy"
        } else if (rain == 6) {
          this.forecast.sky = "약한 비 또는 눈"
          this.forecast.icon = "mdi-weather-snowy-rainy"
        } else if (rain == 7) {
          this.forecast.sky = "약한 눈"
          this.forecast.icon = "mdi-weather-snowy"
        }

        if (this.weather.now.uv <= 2) {
          this.forecast.uvIcon = "grey-lighten-2"
        } else if (this.weather.now.uv <= 5) {
          this.forecast.uvIcon = "yellow-darken-1"
        } else if (this.weather.now.uv <= 7) {
          this.forecast.uvIcon = "aamber-darken-1"
        } else if (this.weather.now.uv <= 10) {
          this.forecast.uvIcon = "orange-darken-1"
        } else {
          this.forecast.uvIcon = "orange-darken-4"
        }

        this.forecast.now = {
          sky: cloud,
          fall: rain
        }
      },

      parseAir() {
        this.forecast.air.khai = this.parseAirGrade(this.weather.air.khai.grade)
        this.forecast.air.o3   = this.parseAirGrade(this.weather.air.o3.grade)
        this.forecast.air.co   = this.parseAirGrade(this.weather.air.co.grade)
        this.forecast.air.no2  = this.parseAirGrade(this.weather.air.no2.grade)
        this.forecast.air.so2  = this.parseAirGrade(this.weather.air.so2.grade)
        this.forecast.air.pm10 = this.parseAirGrade(this.weather.air.pm10.grade)
        this.forecast.air.pm25 = this.parseAirGrade(this.weather.air.pm25.grade)
      },

      parseAirGrade(grade) {
        if (grade == 1) {
          return "light-blue-accent-3"
        } else if (grade == 2) {
          return "green-darken-1"
        } else if (grade == 3) {
          return "amber-darken-4"
        } else if (grade == 4) {
          return "deep-orange-accent-4"
        } else {
          return "grey-lighten-4"
        }
      },

      parseToday() {
        let today = "어제보다"

        try {
          let tem = this.weather.yesterday.temperature - this.weather.now.temperature
          let gap = Math.abs(tem).toFixed(1)

          if (tem > 0) {
            today = `${today} ${gap}° 낮`
          } else if (tem < 0) {
            today = `${today} ${gap}° 높`
          } else {
            today = `어제와 기온이 같`
          }

          let hum =  0

          if (this.weather.now.temperature < 15) {
            hum = (this.weather.now.humidity < 60) ? 1 : ((this.weather.now.humidity > 80) ? -1 : 0)
          } else if (this.weather.now.temperature <= 20) {
            hum = (this.weather.now.humidity < 50) ? 1 : ((this.weather.now.humidity > 70) ? -1 : 0)
          } else if (this.weather.now.temperature <= 23) {
            hum = (this.weather.now.humidity < 40) ? 1 : ((this.weather.now.humidity > 60) ? -1 : 0)
          } else {
            hum = (this.weather.now.humidity < 30) ? 1 : ((this.weather.now.humidity > 50) ? -1 : 0)
          }
          
          if (hum > 0) {
            today = `${today}고, 건조해요`
          } else if (hum < 0) {
            today = `${today}고, 습해요`
          } else {
            today = `${today}아요`
          }

        } catch(e) {
          today = ""
        }

        this.forecast.today = today
      },

      parseShort() {
        let base = this.weather.short
        let more = this.weather.more

        if (base && (base?.sky.length > 0)) {
          let baseTime = (parseInt(base.baseTime, 10) / 100) + 1

          for (let i = 0; i < 6; i++) {
            baseTime++

            if (baseTime > 23) {
              baseTime = 0
            }

            this.forecast.short.push({
              time: String(baseTime).padStart(2, '0'),
              humidity: base.humidity[i],
              rainMeter: base.rainMeter[i],
              rainType: base.rainType[i],
              sky: base.sky[i],
              temperature: base.temperature[i],
              windDeg: base.windDeg[i],
              windSpeed: base.windSpeed[i]
            })
          }

          if (more) {
            let moreTime  = (parseInt(more.baseTime, 10) / 100) + 1
            let nextIndex = 0

            for (let i = 1; i < 24; i++) {
              moreTime++

              if (moreTime > 23) {
                moreTime = 0
              }

              if (moreTime == baseTime) {
                nextIndex = i
                break
              }
            }
            
            for (let i = 0; i < 30; i++) {
              let idx = i + nextIndex
              moreTime++

              if (moreTime > 23) {
                moreTime = 0
              }

              this.forecast.short.push({
                time: String(moreTime).padStart(2, '0'),
                humidity: more.humidity[idx],
                rainMeter: more.rainMeter[idx],
                rainType: more.rainType[idx],
                sky: more.sky[idx],
                temperature: more.temperature[idx],
                windDeg: more.windDeg[idx],
                windSpeed: more.windSpeed[idx]
              })
            }
          }

          let today = true
          this.estimate = {
            rain: 0,
            snow: 0
          }

          this.forecast.short.forEach((s) => {
            if (s.time == '00') {
              today = false;
              return ;
            }
            
            if (today) {
              if ((s.rainType == 1) || (s.rainType == 2) || (s.rainType == 4))   {
                this.estimate.rain += s.rainMeter; 
              } else if (s.rainType == 3) {
                this.estimate.snow += s.rainMeter; 
              }
            }
          })
          
        }

        /*
        없음(0), 비(1), 비/눈(2), 눈(3), 빗방울(5), 빗방울눈날림(6), 눈날림(7) 
        없음(0), 비(1), 비/눈(2), 눈(3), 소나기(4) 
        */
      },

      parseSatllite() {
        const oDate = new Date()
        oDate.setMinutes(oDate.getMinutes() - 10)

        let year  = oDate.getUTCFullYear()
        let month = String(oDate.getUTCMonth() + 1).padStart(2, '0')
        let day   = String(oDate.getUTCDate()).padStart(2, '0')
        let hour  = String(oDate.getUTCHours()).padStart(2, '0')
        let min   = oDate.getUTCMinutes()

        if ((min % 2) > 0) {
          min = min - 1
        }

        min = String(min).padStart(2, '0')

        this.satellite.datetime = `${year}${month}${day}${hour}${min}`
        
        if (this.satellite.datetime) {
          let imagePath = `https://www.kma.go.kr/repositary/image/sat/gk2a/KO/gk2a_ami_le1b_ir105_ko020lc_${this.satellite.datetime}.thn.png`

          if (this.satellite.path != imagePath) {
            this.satellite.path = imagePath
          }
        }
      },

      getAirGradeColor(grade) {
        if (grade == 1) {
          return "#60a5fa"
        } else if (grade == 2) {
          return "#4ade80"
        } else if (grade == 3) {
          return "#facc15"
        } else if (grade == 4) {
          return "#f87171"
        } else {
          return "#e5e7eb"
        }
      },

      getUvLevelColor(level) {
        if (level <= 2) {
          return "#60a5fa"
        } else if (level <= 5) {
          return "#4ade80"
        } else if (level <= 7) {
          return "#facc15"
        } else if (level <= 10) {
          return "#f87171"
        } else {
          return "#b91c1c"
        }
      },

      getDiscomfortIndexColor(index) {
        if (index <= 68) {
          return "#60a5fa"
        } else if (index <= 75) {
          return "#4ade80"
        } else if (index <= 80) {
          return "#facc15"
        } else if (index <= 83) {
          return "#f87171"
        } else {
          return "#b91c1c"
        }
      },

      getPredictSkyIcon(sky, fall, hour=12) {
        let icon = "mdi-weather-sunny"

        if (sky == 1) {
          icon = "mdi-weather-sunny"

          if ((hour < 6) || (hour > 22)) {
            icon = "mdi-weather-night"
          }
        } else if (sky == 2) {
          icon = "mdi-weather-partly-cloudy"

          if ((hour < 5) || (hour > 22)) {
            icon = "mdi-weather-night-partly-cloudy"
          }
        } else if (sky == 3) {
          icon = "mdi-weather-partly-cloudy"

          if ((hour < 5) || (hour > 22)) {
            icon = "mdi-weather-night-partly-cloudy"
          }
        } else if (sky == 4) {
          icon = "mdi-weather-cloudy"
        }
        
        if (fall == 1) {
          icon = "mdi-weather-pouring"
        } else if (fall == 2) {
          icon = "mdi-weather-snowy-rainy"
        } else if (fall == 3) {
          icon = "mdi-weather-snowy-heavy"
        } else if (fall == 4) {
          icon = "mdi-weather-pouring"
        } else if (fall == 5) {
          icon = "mdi-weather-rainy"
        } else if (fall == 6) {
          icon = "mdi-weather-snowy-rainy"
        } else if (fall == 7) {
          icon = "mdi-weather-snowy"
        }

        return icon
      },
      
      getPredictSkyColor(sky, fall) {
        let color = "blue-lighten-3"

        if (sky == 1) {
          color = "blue-lighten-3"
        } else if (sky == 2) {
          color = "blue-grey-lighten-4"

        } else if (sky == 3) {
          color = "blue-grey-lighten-4"

        } else if (sky == 4) {
          color = "blue-grey-lighten-2"
        }
        
        if (fall == 1) {
          color = "blue-grey-lighten-2"
        } else if (fall == 2) {
          color = "blue-grey-darken-3"
        } else if (fall == 3) {
          color = "blue-grey-lighten-3"
        } else if (fall == 4) {
          color = "blue-grey-lighten-3"
        } else if (fall == 5) {
          color = "blue-grey-lighten-2"
        } else if (fall == 6) {
          color = "blue-grey-lighten-4"
        } else if (fall == 7) {
          color = "blue-grey-lighten-4"
        }

        return `${color} drop-shadow-sm`
      },

      setAlert (text) {
        alert(text)
      },
    }
  }
</script>