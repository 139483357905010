<template>
  <div class="lb-pull-refresh" ref="root">
    <div
      class="lb-pull-refresh__track" :style="trackStyle"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
      @touchcancel="onTouchend"
    >
      <div :style="getHeadStyle" class="lb-pull-refresh__head">
        <v-progress-circular
          :rotate="360"
          :size="30"
          :width="5"
          :model-value="(distance/headHeight)*100"
          color="teal"
          :indeterminate="(distance>=headHeight)"
        >
        </v-progress-circular>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import PullRefresh from "pull-refresh-vue3"

const DEFAULT_HEAD_HEIGHT = 60;

export default defineComponent({
  name: 'pull-refresh',
	extends: PullRefresh,
  emits: ['refresh', 'update:modelValue', 'update:distance'],
  props: {
    headHeight: {
      type: [Number, String],
      default: DEFAULT_HEAD_HEIGHT,
    },
  },
  setup(props, { emit, slots }) {
		return { ...PullRefresh.setup(props, { emit, slots }) };
	}
})
</script>
