import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import { createVuetify } from 'vuetify'

import App from './App.vue'
import axios from 'axios'

import 'vuetify/styles'
import './registerServiceWorker'
import "./assets/css/output.css"
import "./assets/css/fonts.css"
import '@mdi/font/css/materialdesignicons.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import store from './store/store.js'

const vuetify = createVuetify({
  components,
  directives,
})



const app = createApp(App)
const head = createHead()

app.config.globalProperties.$store = store
app.config.globalProperties.axios = axios

app.use(vuetify)
app.use(head)

app.mount('#app')